import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsCheck, BsFileCheck } from "react-icons/bs";
import { FaCheck } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Divider, DoubleButton } from "src/components";
import { PiCopySimpleFill } from "react-icons/pi";

import styled from "styled-components";
import { copyToClipBoard } from "src/utils";

function checkMobile() {
  var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

  if (varUA.indexOf("android") > -1) {
    //안드로이드
    return "android";
  } else if (
    varUA.indexOf("iphone") > -1 ||
    varUA.indexOf("ipad") > -1 ||
    varUA.indexOf("ipod") > -1
  ) {
    //IOS
    return "ios";
  } else {
    //아이폰, 안드로이드 외
    return "other";
  }
}

type Item = { id: number; code: string; name: string; price: number };
const items: Item[] = [
  {
    id: 1,
    code: "membership1",
    name: "매칭 4회 이용권",
    price: 990000,
  },
  // {
  //   id: 2,
  //   code: "membership2",
  //   name: "2회 이용권",
  //   price: 790000 * 2,
  // },
];
type Pay = { id: number; name: string; blocked?: boolean };
const dummyPay: Pay = {
  id: 1,
  name: "신용카드",
};

const ACCOUNT_STRING = "우리은행 1005-302-929567";

export default function Payment() {
  const isIos = checkMobile() === "ios";
  const payMethods: Pay[] = [
    {
      id: 1,
      name: "신용카드",
      // blocked: isIos ? true : false,
    },
    {
      id: 2,
      name: "계좌이체",
    },
    // {
    //   id: 3,
    //   name: "휴대폰",
    //   // blocked: true,
    // },
  ];

  const [activeItem, setActiveItem] = useState<Item>(items[0]);
  const [activePay, setActivePay] = useState<Pay>(payMethods[0]);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [isCopied, setIsCopied] = useState(false);

  const handleClickAccountCopy = async () => {
    await copyToClipBoard(ACCOUNT_STRING);
    setIsCopied(true);
  };
  const handleCallKgCard2 = (itemCodeInsert?: string) => {
    (async () => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_TEESHOT_SERVER_URL}/kgMob/credit/callPaymentWindow?callType=I`,
          {
            token: localStorage?.getItem("auth-token"),
            itemCode: itemCodeInsert || activeItem.code,
            // itemCode: "membership-test",
            paymentEndBackUrl: "https://membership.teeshot.co.kr",
            cancelUrl: `${process.env.REACT_APP_TEESHOT_SERVER_URL}/kgMob/cancel`,
          }
        );
        if (!res.data.success) {
          console.log("res?.data?.message :", res?.data?.message);
          console.log("res?.data :", res?.data);
          throw new Error(res?.data?.message || "결제 불러오기 오류");
        }
        // document.write(res.data.data);
        navigate("/kgContoller", { state: { htmlToShow: res.data.data } });
      } catch (e) {
        console.log("e :", e);
        alert(e);
      }
    })();
  };

  const handleCallKgCardDev = (itemCodeInsert?: string) => {
    (async () => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_TEESHOT_DEV_SERVER_URL}/kgMob/credit/callPaymentWindow?callType=I`,
          {
            token: process.env.REACT_APP_TEESHOT_DEV_TOKEN,
            itemCode: "membership-test",
            paymentEndBackUrl: "https://membership.teeshot.co.kr",
            cancelUrl: `${process.env.REACT_APP_TEESHOT_SERVER_URL}/kgMob/cancel`,
            additionalNoti: { key: "yearBookingRound", param: { roundId: 123456 } },
          }
        );
        if (!res.data.success) {
          console.log("res?.data?.message :", res?.data?.message);
          console.log("res?.data :", res?.data);
          throw new Error(res?.data?.message || "결제 불러오기 오류");
        }
        // document.write(res.data.data);
        navigate("/kgContoller", { state: { htmlToShow: res.data.data } });
      } catch (e) {
        console.log("e :", e);
        alert(e);
      }
    })();
  };

  useEffect(() => {
    const element = document.querySelector("#top");
    if (element) {
      element?.scrollIntoView({ block: "end" });
    }
  }, [window.location.href]);
  return (
    <>
      <Container className="page-pd">
        <div className="main">
          <section>
            <h3 id="top">멤버십 이용권</h3>
            <div className="product-box">
              {items.map((item, idx) => {
                return (
                  <div
                    className={`product-item ${item.id === activeItem.id ? "selected" : ""}`}
                    key={`product-${item.id}-${idx}`}
                    onClick={() => {
                      const selected = items.find((_item) => _item.id === item.id);
                      if (!selected) {
                        return;
                      }
                      setActiveItem(selected);
                    }}
                  >
                    <div className="icon">
                      <FaCheck className="icon-svg" size={"22px"} />
                    </div>
                    <div className="name-and-price">
                      <div className="name">{item.name}</div>
                      <div className="price">{item.price.toLocaleString()} 원</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
          <Divider />
          <section>
            <h3>결제수단</h3>
            <div className="pay-method-box">
              {payMethods.map((p, idx) => {
                return (
                  <div
                    className={`pay-method ${activePay.id === p.id ? "selected" : ""}`}
                    key={`pay-${p.id}-${idx}`}
                    onClick={() => {
                      if (p.blocked) {
                        alert("준비중입니다. 계좌이체를 이용해주세요.");
                        return;
                      }
                      const selected = payMethods.find((_item) => _item.id === p.id);
                      if (!selected) {
                        return;
                      }
                      setActivePay(selected);
                    }}
                  >
                    {p.id === activePay.id && (
                      <div className="icon">
                        <FaCheck className="icon-svg" size={"18px"} />
                      </div>
                    )}
                    {p.name}
                  </div>
                );
              })}
            </div>
            {activePay?.id === 2 && (
              <div className="d-flex fd-c gap-2">
                <img
                  className="acc"
                  src="https://api.v2.teeshot.co.kr/event/resource/images/mem_account.png"
                  alt="temp-acc"
                />
                <Button
                  $buttonSize="sm"
                  className="pd-2 bold d-flex gap-1"
                  $colorTheme="green"
                  $round
                  onClick={() => handleClickAccountCopy()}
                >
                  계좌번호 복사하기
                  <PiCopySimpleFill />
                  {isCopied && " (복사됨)"}
                </Button>
              </div>
            )}
          </section>
          <Divider />
          <section>
            <div className="temp">
              <h3>멤버십 이용약관</h3>
              <div
                className="hb"
                onClick={() => {
                  const zz = prompt("");
                  const num = process.env.REACT_APP_TEMP_NUM;
                  if (num === zz) {
                    handleCallKgCardDev();
                    // handleCallKgCard2("membership-test");
                  }
                  // const zz = prompt("");
                  // const num = process.env.REACT_APP_TEMP_NUM;
                  // if (num === zz) {
                  //   setActivePay(dummyPay);
                  // }
                }}
              ></div>
              {/* <div
                className="hb"
                onClick={() => {
                  const zz = prompt("");
                  const num = process.env.REACT_APP_TEMP_NUM;
                  if (num === zz) {
                    setActivePay(dummyPay);
                  }
                }}
              ></div> */}
            </div>

            <div>
              ▶ 본 멤버쉽 이용권 구매는 선결제 상품으로 미사용상태인경우에는 결제일로부터 7일이내
              구매취소/환불이 가능하며, 사용한 경우에는 취소/환불이 불가합니다.
            </div>
            <div className="mt-2">▶ 문의 : 멤버쉽 컨시어지담당 이사 </div>
            <div className="ml-6">010-9128-3489</div>
          </section>
        </div>

        <DoubleButton
          nagativeMargin
          left={{
            name: "취소",
            // buttonCompProps: { $colorTheme:"darkGreen1" },
            onClick: () => {
              navigate(-1);
            },
            size: "md",
            themeColor: "lightGray2",
          }}
          right={{
            name: "다음",
            buttonCompProps: {
              disabled: (() => {
                switch (activePay?.id) {
                  case 1: {
                    return false;
                  }
                  default: {
                    return true;
                  }
                }
              })(),
            },
            onClick: () => {
              switch (activePay?.id) {
                case 1: {
                  handleCallKgCard2();

                  return;
                }
                default: {
                  return;
                }
              }
            },
            size: "md",
            themeColor: "green",
          }}
        />
      </Container>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;

  .temp {
    display: flex;
    justify-content: space-between;
    .hb {
      width: 20px;
      height: 20px;
      border-bottom: 1px solid gray;
      margin-right: 20px;
    }
  }

  .main {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 10px;
  }
  h3 {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .product-box {
    display: flex;
    flex-direction: column;
    gap: 5px;
    .product-item {
      display: flex;
      gap: 10px;
      padding: 15px;
      border: 1px solid gray;
      border-radius: 5px;
      cursor: pointer;
      &.selected {
        background-color: #7f7f7f;
        color: white;
        .icon {
          .icon-svg {
            display: initial;
          }
        }
      }
      .icon {
        flex-basis: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon-svg {
          display: none;
        }
      }
      .name-and-price {
        display: flex;
        justify-content: space-between;
        flex: 1;
        & > * {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .name {
        }
        .price {
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
  }

  .acc {
    margin-top: 10px;
    width: 100%;
  }
  .acc-info {
    margin: 10px;
    border: 1px solid gray;
    padding: 10px;
    border-radius: 5px;
  }

  .pay-method-box {
    display: flex;
    gap: 10px;
    .pay-method {
      .icon {
        /* flex-basis: 30px; */
        display: flex;
        justify-content: center;
        align-items: center;
        .icon-svg {
          /* display: none; */
        }
      }
      cursor: pointer;
      flex: 1;
      display: flex;
      justify-content: center;
      gap: 5px;
      align-items: center;
      border: 1px solid gray;
      border-radius: 5px;
      padding: 10px;
      &.selected {
        background-color: #7f7f7f;
        color: white;
      }
    }
  }
`;
