import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FaArrowLeft } from "react-icons/fa6";
import { BsPatchQuestion } from "react-icons/bs";
import { colors } from "src/styles/theme";
import Button from "../Button";

export default function MainLayout() {
  return (
    <Contianer>
      <Header />
      <OutletCover>
        <Outlet />
      </OutletCover>
    </Contianer>
  );
}
const Header = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  return (
    <StyledHeader>
      <div>
        {pathname === "/main/m" || pathname === "/main/f" ? (
          <Button
            key={window.location.href + window.location.search}
            id={"close-button"}
            // close-button => app에서 주입받는 script를 위한 id
            $colorTheme="white"
          >
            <FaArrowLeft size={"20px"} />
          </Button>
        ) : (
          <Button
            key={window.location.href + window.location.search}
            id={`${pathname}-${Date.now()}`}
            $colorTheme="white"
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaArrowLeft size={"20px"} />
          </Button>
        )}
      </div>
      <h2>멤버십</h2>
      <div>
        <Button $colorTheme="white" id="pay-history-button" $buttonSize="sm" className="bold">
          이용내역
          {/* <BsPatchQuestion size={"20px"} /> */}
          {/* {" "} */}
        </Button>
      </div>
    </StyledHeader>
  );
};
const OutletCover = styled.div`
  height: calc(100dvh - 55px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  background-color: #000000;
  color: white;
`;

const Contianer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
`;
const StyledHeader = styled.header`
  background-color: #ffffff;
  font-size: 18px;
  height: 55px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid ${colors.gray.second};
  > :first-child,
  > :last-child {
    flex-basis: 80px;
    display: flex;
  }
  > :first-child {
    justify-content: flex-start;
  }
  > :last-child {
    justify-content: flex-end;
  }
`;

const Body = styled.div`
  flex: 1;
`;
