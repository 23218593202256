import { Accordion as Acc, AccordionItem as Item } from "@szhsin/react-accordion";
import { GoTriangleDown } from "react-icons/go";
import { styled } from "styled-components";
import { ReactNode } from "react";
import { colors } from "src/styles/theme";

const AccordionItem = ({ header, ...rest }: any) => {
  return (
    <Item
      {...rest}
      header={
        <>
          <div className="d-flex jc-sb ai-c w-100">
            <span>{header}</span>
            <div>
              <GoTriangleDown className="chevron" size={30} />
            </div>
          </div>
        </>
      }
      className={"item"}
      buttonProps={{
        className: ({ isEnter }) => `${"itemBtn"} ${isEnter && "itemBtnExpanded"}`,
      }}
      contentProps={{ className: "itemContent" }}
      panelProps={{ className: "itemPanel" }}
    />
  );
};

export default function AccordionComponent(props: {
  tamplates: { title: ReactNode; content: ReactNode }[];
}) {
  return (
    <Continaer>
      <div className="accordion">
        <Acc transition transitionTimeout={250}>
          {props.tamplates.map((t, idx) => {
            return (
              <AccordionItem key={`faq-acco-${idx}`} header={t.title}>
                {t.content}
              </AccordionItem>
            );
          })}
        </Acc>
      </div>
    </Continaer>
  );
}

const Continaer = styled.div`
  .accordion {
    /* border-top: 1px solid #ccc; */
    /* margin-top: 1rem; */
  }

  .item {
    /* border-bottom: 1px solid #ccc; */
    &:not(:last-of-type) {
      margin-bottom: 15px;
    }
  }

  .itemBtn {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 12px;
    font-size: 16px;
    text-align: left;
    color: white;
    font-weight: bold;
    background-color: ${colors.green.first};
    border: none;
  }

  .itemBtn:hover {
    /* background-color: #f3f3f3; */
  }

  .itemBtnExpanded {
    background-color: ${colors.green.first};
  }

  .itemContent {
    transition: height 0.25s cubic-bezier(0, 0, 0, 1);
  }

  .itemPanel {
    padding: 1rem;
    border: 1px solid ${colors.gray.first};
    background-color: #fff;
    /* background-color: #efffda; */
  }

  .chevron {
    margin-left: auto;
    transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
  }

  .itemBtnExpanded .chevron {
    transform: rotate(180deg);
  }
`;
