import { useState } from "react";
import { IoCallSharp } from "react-icons/io5";
import { PiCopySimpleFill } from "react-icons/pi";
import { copyToClipBoard } from "src/utils";
import styled from "styled-components";
import Button from "./Button";

const PHONE_NUMBER = "010-9128-3489";

export default function ContactPart() {
  const [isCopied, setIsCopied] = useState(false);

  const handleClickCopy = async () => {
    await copyToClipBoard(PHONE_NUMBER);
    setIsCopied(true);
  };


  const handleClickPhoneCall = () => {
    window.open(`tel:${PHONE_NUMBER}`, "_system");
  };

  return (
    <Container>
      <div className="contact-part">
        <div className="mt-2 d-flex ai-c jc-sb">
          ▶ 멤버쉽 컨시어지담당 이사
          {/* <div className="v-check" {...longPressProps}></div> */}
        </div>
        <div className="mb-2">
          <Button $round $colorTheme="darkGreen1" onClick={() => handleClickPhoneCall()}>
            <IoCallSharp size={"24"} />
          </Button>
          {PHONE_NUMBER}{" "}
          <PiCopySimpleFill
            size={"22"}
            onClick={() => {
              handleClickCopy();
            }}
          />
          {isCopied ? <span className="copy-txt">(복사됨)</span> : null}
        </div>
      </div>
    </Container>
  );
}

const Container = styled.div`
  .contact-part {
    color: white;
    /* font-weight: bold; */
    font-size: 18px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
    > * {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .copy-txt {
      font-size: 14px;
    }
  }
`;
