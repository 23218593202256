import { useNavigate } from "react-router-dom";
import { Button } from "src/components";
import ApplyPart from "src/components/ApplyPart";
import ContactPart from "src/components/ContactPart";
import FAQ, { FAQ_TAMPLATES_MALE } from "src/components/FAQ";
import SwiperComponent from "src/components/SwiperComponent";
import styled from "styled-components";

export default function MainMale() {
  // const [isCopied, setIsCopied] = useState(false);
  const navigate = useNavigate();
  return (
    <Conatiner className="page-pd d-flex fd-c gap-12">
      {/* <section></section> */}
      <section>
        <div className="fw-b fs-12 mt-3">골프장에서 만나는</div>
        <div className="fw-b fs-12 mb-3">특별한 인연</div>
        <div className="fs-8 ">Private 파트너 매칭 서비스</div>
        <div className="fs-8 mb-4">티샷 멤버십</div>
        <div className="page-pd-negative-side img-box-main-male">
          <img
            className=""
            src="https://api.v2.teeshot.co.kr/event/resource/images/membership/mem_ai_male_main.jpg"
            alt=""
          />
        </div>
      </section>
      <section>
        <div className="fs-8">골프,</div>
        <div className="fs-8 mb-3">그 이상의 특별한 경험</div>
        <div>
          <SwiperComponent
            // initialSlide={}
            slideContentList={[
              <img
                src="https://api.v2.teeshot.co.kr/event/resource/images/membership/m_s_1.jpg"
                alt=""
              />,
              <img
                src="https://api.v2.teeshot.co.kr/event/resource/images/membership/m_s_2.jpg"
                alt=""
              />,
              <img
                src="https://api.v2.teeshot.co.kr/event/resource/images/membership/m_s_3.jpg"
                alt=""
              />,
              <img
                src="https://api.v2.teeshot.co.kr/event/resource/images/membership/m_s_4.jpg"
                alt=""
              />,
            ]}
            swiperKey="yb-guide"
          />
        </div>
      </section>
      <section className="d-flex fd-c ai-c">
        <div className="fs-12 fw-b">최고의 파트너와</div>
        <div className="fs-12 fw-b">함께하세요!</div>
      </section>
      <section className="d-flex fd-c gap-2">
        <ContactPart />
        <ApplyPart />
        {/* <div>컨시어지 담당이사 전화번호</div>
        <div>무료 상담신청</div> */}
      </section>
      <section className="bgc-w pd-2 d-flex fd-c gap-2 ai-s">
        <div className="d-flex gap-2">
          <div className="num-box">1</div>
          <div className="d-flex ai-c fs-5">
            <div>멤버십 금액: ₩ 990,000</div>
          </div>
        </div>
        <div className="d-flex gap-2 fs-5 ai-s">
          <div className="num-box">2</div>
          <div className="fs-3">
            <div className="fs-5 mb-2">서비스 혜택:</div>
            <div className="mb-2">
              티샷에서 인증된 여성 서포터즈 회원님들 중 고객님과 딱 맞는 파트너를 컨시어지로
              담당자가 직접 매칭해드립니다!
            </div>
            <div className="d-flex fd-c fw-b">
              <div className="start-mark fc-red">여성 서포터즈 매칭서비스 4회 제공</div>
              <div className="start-mark">라운드 전 사전미팅 원하시면 6회까지 가능</div>
              <div className="start-mark">라운드: 스크린 / 당일골프 / 국내,해외골프</div>
              <div className="start-mark">사용 기간 제한 없음</div>
            </div>
          </div>
        </div>
      </section>
      <section className="d-flex fd-c">
        <Button
          // className="bold"
          $colorTheme="yellow2"
          $buttonSize="md"
          onClick={() => {
            navigate("/payment");
            // alert("상담신청 후 이용해주세요.");
          }}
        >
          멤버십 이용권
        </Button>
      </section>
      <section className="d-flex fd-c ai-c">
        <div className="fs-12 fw-b mb-3">FAQ</div>
        <FAQ templates={FAQ_TAMPLATES_MALE} />
      </section>
    </Conatiner>
  );
}

const Conatiner = styled.div`
  .num-box {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: white;
    background-color: black;
    min-width: 30px;
    border-radius: 5px;
  }

  .img-box-main-male {
    img {
      height: 400px;
      object-fit: cover; /* Optional: Adjust how the image fills the height */
    }
  }
  .start-mark {
    position: relative;
    &::before {
      content: "-";
      left: -10px;
      position: absolute;
    }
  }
  .swiper {
    z-index: 0;
  }

  .swiper-wrapper {
    transition-timing-function: linear;
  }
  .swiper-scrollbar,
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
  img {
    width: 100%;
  }

  .swiper-pagination {
    position: absolute;
    bottom: 15px;
    left: 15px;
    width: auto;
  }
  .swiper-pagination-bullet {
  }
  .swiper-pagination-bullet-active {
    background-color: #3e3e3e;
  }
`;
