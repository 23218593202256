import { useNavigate } from "react-router-dom";
import ApplyPart from "src/components/ApplyPart";
import ContactPart from "src/components/ContactPart";
import FAQ, { FAQ_TAMPLATES_FEMALE, FAQ_TAMPLATES_MALE } from "src/components/FAQ";
import SwiperComponent from "src/components/SwiperComponent";
import styled from "styled-components";
import { FaChevronRight } from "react-icons/fa6";
import { FaCircle } from "react-icons/fa";

export default function MainMale() {
  // const [isCopied, setIsCopied] = useState(false);
  const navigate = useNavigate();
  return (
    <Conatiner className="page-pd d-flex fd-c gap-12">
      <section>
        <div className="fw-b fs-12 mt-3">골프장에서 만나는</div>
        <div className="fw-b fs-12 mb-3">특별한 인연</div>
        <div className="fs-8 ">Private 파트너 매칭 서비스</div>
        <div className="fs-8 mb-4">티샷 멤버십 서포터즈</div>
        <div className="page-pd-negative-side img-box-main-male">
          <img
            className=""
            src="https://api.v2.teeshot.co.kr/event/resource/images/membership/mem_ai_female_main.jpg"
            alt=""
          />
        </div>
      </section>
      <section>
        <div className="fs-8">골프로 시작되는 인연</div>
        <div className="fs-8 mb-3">서포터즈로 함께하세요</div>
        <div>
          <SwiperComponent
            // initialSlide={}
            slideContentList={[
              <img
                src="https://api.v2.teeshot.co.kr/event/resource/images/membership/f_s_1.jpg"
                alt=""
              />,
              <img
                src="https://api.v2.teeshot.co.kr/event/resource/images/membership/f_s_2.jpg"
                alt=""
              />,
              <img
                src="https://api.v2.teeshot.co.kr/event/resource/images/membership/f_s_3.jpg"
                alt=""
              />,
              <img
                src="https://api.v2.teeshot.co.kr/event/resource/images/membership/f_s_4.jpg"
                alt=""
              />,
            ]}
            swiperKey="yb-guide"
          />
        </div>
      </section>
      <section className="d-flex fd-c ai-c">
        <div className="fs-12 fw-b">특별한 순간,</div>
        <div className="fs-12 fw-b">지금 신청하세요!</div>
      </section>
      <section className="d-flex fd-c gap-2">
        <ContactPart />
        <ApplyPart />
        {/* <div>컨시어지 담당이사 전화번호</div>
        <div>무료 상담신청</div> */}
      </section>
      <section className="bgc-w pd-3 d-flex fd-c gap-5 ">
        <div className="paper-top">2025 티샷 서포터즈 모집요강</div>
        <div className="fs-3 ">
          <FaCircle fontSize={8} /> 서포터즈 지원대상:{" "}
          <span className="fc-gray">골프를 사랑하는 모든 분~</span>
        </div>
        <div className="w-100 d-flex fd-c gap-1 fs-3">
          <div className="mb-1">
            <FaCircle fontSize={8} /> 선정절차
          </div>
          <div className="as-str d-flex jc-sb ai-c w-100">
            <div className="d-flex fd-c ai-c gap-2">
              <div className="icon-box">
                <img src="/check.svg" alt="" />
              </div>
            </div>
            <div>
              <FaChevronRight />
            </div>
            <div className="d-flex fd-c ai-c gap-2">
              <div className="icon-box">
                <img src="/laptop.svg" alt="" />
              </div>
            </div>
            <div>
              <FaChevronRight />
            </div>
            <div className="d-flex fd-c ai-c gap-2">
              <div className="icon-box">
                <img src="/call.svg" alt="" />
              </div>
            </div>
          </div>
          <div className="d-flex jc-sb pd-2 fs-2">
            <div>지원신청</div>
            <div>내부심사</div>
            <div className="d-flex fd-c">
              <div>최종합격</div>
              <div className="fc-gray">(개별통보)</div>
            </div>
          </div>
        </div>
        <div className="fs-3">
          <div className="mb-1">
            <FaCircle fontSize={8} /> 활동내용:
          </div>
          <div>
            티샷이 추천하고 검증한 프리미엄 회원과의
            <span className="fc-red"> 무료 라운드(그린피+카트)</span>의 기회를 우선 제공해 드립니다!
          </div>
        </div>
        <div className="paper-bottom">
          <div className="mb-1 fw-b">우대사항</div>
          <div className="d-flex fd-c fs-3 gap-1">
            <div>- 골프 실력이 출중하신 분</div>
            <div>- 연예인급 외모를 가지신 분</div>
            <div>- 골프 관련 라이센스 보유하신 분(프로님)</div>
          </div>
        </div>
      </section>
      <section className="d-flex fd-c ai-c">
        <div className="fs-12 fw-b mb-3">FAQ</div>
        <FAQ templates={FAQ_TAMPLATES_FEMALE} />
      </section>
    </Conatiner>
  );
}

const Conatiner = styled.div`
  .paper-top {
    background-color: #0b7b4b;
    color: white;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -15px;
    margin-left: -15px;
    margin-right: -15px;
    font-size: 18px;
    font-weight: bold;
  }
  .paper-bottom {
    background-color: #efffda;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: -15px;
    padding: 15px;
    color: #164235;
  }
  .icon-box {
    width: 70px;
    background-color: #e0e0e0;
    padding: 10px;
    border-radius: 9999px;
    img {
      width: 100%;
    }
  }
  .num-box {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: white;
    background-color: black;
    min-width: 30px;
    border-radius: 5px;
  }

  .img-box-main-male {
    img {
      height: 400px;
      width: 100%;
      object-fit: cover;
    }
  }
  .swiper {
    z-index: 0;
    img {
      width: 100%;
    }
  }

  .swiper-wrapper {
    transition-timing-function: linear;
  }
  .swiper-scrollbar,
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }

  .swiper-pagination {
    position: absolute;
    bottom: 15px;
    left: 15px;
    width: auto;
  }
  .swiper-pagination-bullet {
  }
  .swiper-pagination-bullet-active {
    background-color: #3e3e3e;
  }
`;
