import { useState } from "react";
import Modal from "./Modal";
import { createTeeshotAxiosInstance } from "src/http";
import SreenCoverLoading from "./SreenCoverLoading";
import Button from "./Button";

export default function ApplyPart() {
  const [isModalOpened, setIsModalOpened] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const bannerId = localStorage.getItem("banner-id");

  const handleClickApply = () => {
    (async () => {
      setIsLoading(true);

      try {
        if (!bannerId) {
          throw new Error("배너정보 없음");
        }
        const bannerDataRes = await createTeeshotAxiosInstance().get(`/banner/${bannerId}`);
        if (!bannerDataRes.data?.success) {
          throw new Error(bannerDataRes.data?.message || "배너정보 없음");
        }
        const res = await createTeeshotAxiosInstance().post(`/banner/interpark/${bannerId}`, {
          name: bannerDataRes.data?.data?.title,
          date: null,
          memo: null,
        });
        if (!res.data?.success) {
          console.log("res.data?.massage :", res.data?.massage);
          throw new Error("이미 신청하셨습니다.");
        }
        setIsModalOpened(true);
      } catch (e) {
        alert(`${e}`);
      } finally {
        setIsLoading(false);
      }
    })();
  };
  return (
    <>
      <Button
        // className="bold"
        $colorTheme="pink"
        $buttonSize="md"
        onClick={() => {
          // api ..

          // setIsModalOpened(true);
          handleClickApply();
        }}
      >
        무료 상담신청
      </Button>
      {isLoading && <SreenCoverLoading />}
      {isModalOpened && (
        <Modal
          setModalState={setIsModalOpened}
          content={
            <>
              <div className="d-flex flex-1 fd-c jc-c gap-2 ai-c">
                <div>상담 신청을 남겨 주셔서 감사합니다.</div>
                <div>멤버십 담당자가 곧 전화드리도록 하겠습니다</div>
                <div>- 티샷 -</div>
              </div>
            </>
          }
        />
      )}
    </>
  );
}
