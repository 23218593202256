import "@fontsource/noto-sans-kr";
import "@fontsource/noto-sans-kr/400.css"; // Normal weight
import "@fontsource/noto-sans-kr/700.css"; // Bold weight
import { createGlobalStyle, css } from "styled-components";
import reset from "styled-reset";
const GlobalStyle = createGlobalStyle`

  ${() => {
    return css`
      ${reset}
      *, *::before, *::after {
        box-sizing: border-box;
      }

      body,
      html,
      #root {
        /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
        /* font-family: "Roboto"; */
        font-family: "Noto Sans KR sans-serif";

        /* font-family: " sans-serif"; */
        line-height: 1.3;
        max-width: 800px;
        margin: 0 auto;
        min-height: 100dvh;
      }
      #root {
        display: flex;
        flex-direction: column;
      }
      .page-pd {
        padding: 25px;
      }
      .page-pd-negative-side {
        margin-left: -25px;
        margin-right: -25px;
      }
      .page-pd-negative-top {
        margin-top: -25px;
      }
      .d-flex {
        display: flex;
      }
      .fd-c {
        flex-direction: column;
      }
      .jc-c {
        justify-content: center;
      }
      .jc-sb {
        justify-content: space-between;
      }
      .jc-sa {
        justify-content: space-around;
      }
      .as-c {
        align-self: center;
      }
      .f-1 {
        flex: 1;
      }
      .ai-e {
        align-items: flex-end;
      }
      .ai-s {
        align-items: flex-start;
      }
      .ai-c {
        align-items: center;
      }
      .as-c {
        align-self: center;
      }
      .as-s {
        align-self: flex-start;
      }
      .as-e {
        align-self: flex-end;
      }
      .gap {
        gap: 5px;
      }
      .gap-s {
        gap: 2.5px;
      }
      .gap-1 {
        gap: 5px;
      }
      .input-box-type {
        border: 1px solid black;
        border-radius: 3px;
        height: 45px;
      }
      .cursor-p {
        cursor: pointer;
      }
      .no-click {
        pointer-events: none;
      }

      .w-100 {
        width: 100%;
      }
      .bold,
      .fw-b {
        font-weight: bold;
      }
      .bgc-w {
        background-color: #fff;
        color: black;
      }
      .fc-red {
        color: red;
      }
      .fc-green {
        color: #0b7b4b;
      }
      .fc-gray {
        color: #717171;
      }
      ${() => {
        let cssString = "";
        for (let i = 1; i <= 20; i++) {
          cssString += `
            .gap-${String(i)} {
              gap: ${String(5 * i)}px;
            }

            .mb-${String(i)} {
              margin-bottom: ${String(5 * i)}px;
            }
            .mt-${String(i)} {
              margin-top: ${String(5 * i)}px;
            }
            .ml-${String(i)} {
              margin-left: ${String(5 * i)}px;
            }
            .mml-${String(i)} {
              margin-left: -${String(5 * i)}px;
            }
            .mr-${String(i)} {
              margin-right: ${String(5 * i)}px;
            }
            .pd-${String(i)} {
              padding: ${String(5 * i)}px;
            }
            .pt-${String(i)} {
              padding-top: ${String(5 * i)}px;
            }
            .pb-${String(i)} {
              padding-bottom: ${String(5 * i)}px;
            }
            .pl-${String(i)} {
              padding-left: ${String(5 * i)}px;
            }
            .pr-${String(i)} {
              padding-right: ${String(5 * i)}px;
            }
            .fs-${String(i)} {
              font-size: ${String(8 + 2 * i)}px;
            }
            .flex-${String(i)} {
              flex: ${i};
            }
            
          `;
        }
        return cssString;
      }}
    `;
  }}


`;

export default GlobalStyle;
